import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './components/header';
import Products from './pages/products';
import Whatsapp from './pages/whatsapp';
import Footer from './components/footer';

function App() {
  return (
    <Flex
      minH="100vh"
      w="100%"
      maxW="100%"
      flexDirection="wrap"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Header w="100%" />
      <Box w="100%" display="flex" flexDirection="column" alignItems="center">
        <Products />
      </Box>
      <Whatsapp />
      <Footer />
    </Flex>
  );
}

export default App;
