import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import whatsapp from '../images/whatsapp.png';
function Whatsapp() {
  return (
    <Flex
      display="flex"
      justifyContent="flex-end"
      w="100%"
      mb="30"
      mr="50"
      position="fixed"
      bottom="20px"
      right="20px"
      zIndex="9999"
    >
      <Image
        src={whatsapp}
        w="70"
        h="70"
        borderRadius="50%"
        onClick={() => {
          window.open(
            'https://api.whatsapp.com/send?phone=5534999509836&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos%20da%20MTIG%20Cascalheira.',
            '_blank'
          );
        }}
        _hover={{
          cursor: 'pointer',
        }}
      />
    </Flex>
  );
}

export default Whatsapp;
