import cascalho from '../images/cascalho.jpeg';
import brita from '../images/brita.jpeg';
import areia from '../images/areia.jpeg';
import po from '../images/po_de_brita.jpeg';

export const items = [
  {
    id: 1,
    title: 'Cascalho',
    text: 'Entre em contato para mais informação',
    photo: cascalho,
  },
  {
    id: 2,
    title: 'Areia',
    text: 'Em Breve',
    photo: areia,
  },
  {
    id: 3,
    title: 'Brita 1, 2, 3',
    text: 'Em Breve',
    photo: brita,
  },
  {
    id: 4,
    title: 'Pó de Brita',
    text: 'Em Breve',
    photo: po,
  },
];
