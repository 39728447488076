import {
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import logo from "../images/logo.png";
import { BsChatQuote, BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { HamburgerIcon } from "@chakra-ui/icons";
import { GiMineTruck } from "react-icons/gi";

function Header() {
  const [isOnmobile] = useMediaQuery("(max-width: 768px)");
  return (
    <Flex display="flex" flexDir="column">
      <Flex
        w="100vw"
        h="fit-content"
        pb="20"
        justifyContent="flex-end"
        display="flex"
        flexWrap="wrap"
        borderBottom="3px solid #80AB4B"
      >
        <Flex display="flex" flexDir="row" marginRight="50px">
          <Flex
            mt="22px"
            mr="10px"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5534999509836&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos%20da%20MTIG%20Cascalheira.",
                "_blank"
              );
            }}
          >
            <BsWhatsapp size="20" />
          </Flex>
          <Flex mt="20px" mr="10px">
            <AiOutlinePhone size="25" />
          </Flex>
          <Text mb="1px" fontSize="20px">
            (34) 99950-9836
          </Text>
        </Flex>
        <Flex display="flex" flexDir="row" marginRight="50px">
          <Flex mt="24px" mr="10px">
            <AiOutlineMail size="20" />
          </Flex>

          <Text mb="1px" fontSize="20px">
            mtigcascalheira@gmail.com
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        h="100%"
        display="flex"
        flexDir="row"
        flexWrap="wrap"
        pb="20"
      >
        <Flex w="20%">
          <Image src={logo} w="150" h="150" ml="50" my="10px" />
        </Flex>
        {!isOnmobile ? (
          <Flex
            w="80%"
            alignItems="center"
            justifyContent="flex-end"
            fontSize="25px"
          >
            <Text
              w="fit-content"
              h="fit-content"
              mx="30"
              _hover={{
                color: "#80AB4B",
                cursor: "pointer",
              }}
            >
              INÍCIO
            </Text>
            {/* <Text
              w="fit-content"
              h="fit-content"
              mx="30"
              _hover={{
                color: '#80AB4B',
                cursor: 'pointer',
              }}
            >
              QUEM SOMOS
            </Text> */}
            {/* <Text
              w="fit-content"
              h="fit-content"
              mx="30"
              _hover={{
                color: "#80AB4B",
                cursor: "pointer",
              }}
            >
              PRODUTOS
            </Text> */}
            <Text
              bg="#80AB4B"
              mx="30"
              border="1px solid green"
              py="10"
              px="20"
              borderRadius="30"
              _hover={{
                backgroundColor: "green",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send?phone=5534999509836&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos%20da%20MTIG%20Cascalheira.",
                  "_blank"
                );
              }}
            >
              FAÇA UM ORÇAMENTO
            </Text>
          </Flex>
        ) : (
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
                h="50px"
                w="50px"
                bg="#80AB4B"
                borderRadius="10"
                border="1px solid black"
              />
              <MenuList>
                {/* <MenuItem
                  icon={<AiOutlineQuestionCircle size="25" />}
                  bg="#80AB4B"
                  mb="1"
                >
                  QUEM SOMOS
                </MenuItem> */}
                {/* <MenuItem icon={<GiMineTruck size="25" />} bg="#80AB4B" mb="1">
                  PRODUTOS
                </MenuItem> */}
                <MenuItem
                  icon={<BsChatQuote size="25" />}
                  bg="#80AB4B"
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send?phone=5534999509836&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20produtos%20da%20MTIG%20Cascalheira.",
                      "_blank"
                    );
                  }}
                >
                  FAÇA UM ORÇAMENTO
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Header;
