import { Box, Text } from "@chakra-ui/react";
import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      borderTop="3px solid #80AB4B"
      borderWidth="100%"
      marginTop="10px"
      w="100%"
      color="black"
      as="footer"
      textAlign="center"
      py={4}
      fontSize="20px"
      bg="gray.800"
    >
      <Text>
        {currentYear === 2023
          ? `© 2023 MTIG Cascalheira | All Rights Reserved.`
          : `© 2023-${currentYear} MTIG Cascalheira | All Rights Reserved.`}
      </Text>
    </Box>
  );
}

export default Footer;
