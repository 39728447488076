import { Flex, Heading, Box, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { items } from "../components/data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Products() {
  const [isOnMobile] = useMediaQuery("(max-width: 768px)");
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Flex
      display="flex"
      flexDir="column"
      mb="100px"
      width="50%"
      minHeight="300px"
    >
      <Heading fontSize="40px" textAlign="center" color="#80AB4B">
        Produtos
      </Heading>
      <Slider {...sliderSettings}>
        {items.map((item) => (
          <Box
            key={item.id}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <img
              src={item.photo}
              alt={item.title}
              style={{
                borderRadius: "10%",
                width: isOnMobile ? "70%" : "30%",
                height: isOnMobile ? "70%" : "30%",
                objectFit: "contain",
                marginLeft: isOnMobile ? "15%" : "35%",
              }}
            />
            <Flex
              margin="5px 20px"
              color="black"
              flexDirection="column"
              alignItems="center"
            >
              <Heading fontSize="2rem">{item.title}</Heading>
              <Text fontSize="25px">{item.text}</Text>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Flex>
  );
}

export default Products;
